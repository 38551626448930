// import node module libraries
import React, {useState, useEffect} from 'react';
import { Fragment } from 'react';
import { Col, Row, Nav, Tab, Card, Container, Spinner } from 'react-bootstrap';

// import custom components
import CourseCard from '../../components/courses/CourseCard';
import ProfileCover from '../../components/headers/ProfileCover';
import { useMsal } from '@azure/msal-react';
// import media files
const Avatar3 = require('../../assets/images/avatar/coruja.png');
import Axios from 'axios';
// import data files
import { loginRequest } from '../../authConfig';


const StudentDashboard = () => {

	const [dashboardData, setDashboardData] = useState({});
	const [coursesData, setCoursesData] = useState([]);
	const [spinnerHidden, setDpinnerHidden] = useState(true);
	const [itemStartHereData, setItemStartHereData] = useState();

	const { instance } = useMsal();
	
	let activeAccount: any;

	if (instance) {
		activeAccount = instance.getActiveAccount();		
	}

	async function getConfig()
	{
		
		setDpinnerHidden(false);

		let configAxios;

		await instance.acquireTokenSilent(loginRequest).then(tokenResponse => {
			
			configAxios = {
				headers: {
					Authorization: `Bearer ${tokenResponse.accessToken}`
				}			
			};
			
			Axios.get( 
				process.env.REACT_APP_URLAPI + '/Courses',		  
				configAxios
				).then(result => {
					setCoursesData(result.data);	
					setDpinnerHidden(true);				
				}).catch(console.log);
		});

	}

	async function getStartHere()
	{
		let item : any = {
			permalink : "starthere",
			thumbnail : "https://betatrainers.blob.core.windows.net/images/comece-por-aqui.jpg",
			title : "Comece por aqui",
			description: "Antes de começar em nossa escola, assista o vídeo que preparei para você.",
			duration : "00:00:00"

		}

		setItemStartHereData(item);
	}

	useEffect(() => {
		//Runs only on the first render
		
		const _dashboardData = {
			avatar: Avatar3,
			name: activeAccount && activeAccount.idTokenClaims.given_name.toLocaleString() + " " + activeAccount.idTokenClaims.family_name.toLocaleString(),
			linkname: 'Account Setting',
			link: '/marketing/student/student-edit-profile/',
			verified: true,
			outlinebutton: false,
			level: 'Beginner'
		};

		setDashboardData(_dashboardData);

		getConfig();

		getStartHere();
		
	  }, []);

	

	return (
		<Fragment>
			<section className="pt-5 pb-5">
				<Container>
					{/* User info */}
					<ProfileCover dashboardData={dashboardData} />

					{/* Content */}
					<Row className="mt-0 mt-md-4">
						<Col lg={12} md={12} sm={12}>
							<Row className="mb-6">
								<Col md={12}>
									<Tab.Container defaultActiveKey="bookmarked">
										<Card className="bg-transparent shadow-none ">
											<Card.Header className="border-0 p-0 bg-transparent">
												<Nav className="nav-lb-tab">
													<Nav.Item className="ms-0">
														<Nav.Link
															eventKey="bookmarked"
															className="mb-sm-3 mb-md-0"
														>
															Comece por aqui
														</Nav.Link>
													</Nav.Item>
												</Nav>
											</Card.Header>
											<Card.Body className="p-0">
												<Tab.Content>
													<Tab.Pane
														eventKey="bookmarked"
														className="pb-4 p-4 ps-0 pe-0"
													>
														{/* bookmarked started */}
														{itemStartHereData && <Row>
															<Col sm={12} md={12} lg={12} key="startHereHeader">
																<CourseCard item={itemStartHereData} viewby="list" />
															</Col>
														</Row>}
														{/* end of bookmarked */}
													</Tab.Pane>
												</Tab.Content>
											</Card.Body>
										</Card>
										<Card className="bg-transparent shadow-none ">
											<Card.Header className="border-0 p-0 bg-transparent">
												<Nav className="nav-lb-tab">
													<Nav.Item className="ms-0">
														<Nav.Link
															eventKey="bookmarked"
															className="mb-sm-3 mb-md-0"
														>
															Cursos
														</Nav.Link>
													</Nav.Item>
												</Nav>
											</Card.Header>
											<Card.Body className="p-0">
												<Tab.Content>
													<Tab.Pane
														eventKey="bookmarked"
														className="pb-4 p-4 ps-0 pe-0"
													>
														<Row>
															<Col lg={12} md={12} sm={12}>
																<div className="d-flex justify-content-center">																	
																	<Spinner animation="border" role="status" hidden={spinnerHidden} variant="primary">
																		<span className="visually-hidden">Loading...</span>
																	</Spinner>																	
																</div>
															</Col>
														</Row>
														{/* bookmarked started */}
														<Row>
															{
															
															coursesData.filter(function (datasource) {
																
																return datasource;
															})
																.slice(0, 8)
																.map((item, index) => {
																	return(<Col lg={3} md={6} sm={12} key={index}>
																		<CourseCard item={item} />
																	</Col>);

																})}
														</Row>
														{/* end of bookmarked */}
													</Tab.Pane>
												</Tab.Content>
											</Card.Body>
										</Card>
									</Tab.Container>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</section>
		</Fragment>
	);
};
export default StudentDashboard;

// import node module libraries
import React, { useContext, Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
	Accordion,
	useAccordionButton,
	AccordionContext,
	ListGroup
} from 'react-bootstrap';

import Icon from '@mdi/react';
import { mdiPlay } from '@mdi/js';

interface gkAccordionDefaultProps {
    accordionItems: any;
    itemClass?: any;
	onClick?: any;
	onModuleClick?: any;
}


interface _contextAwareToggle {
	children: any;
	eventKey: any;
	callback?: any;
}

const GKAccordionDefault = (props: gkAccordionDefaultProps ) => {

	const [activeKeyId, setActiveKeyId] = useState<string>();

	const changeActiveKey = (_activeKey: string) => {
		setActiveKeyId(_activeKey);
	}

	const ContextAwareToggle = (contextGk: _contextAwareToggle) => {
		const { activeEventKey } = useContext(AccordionContext);

		const decoratedOnClick = useAccordionButton(
			contextGk.eventKey,
			() => contextGk.callback && contextGk.callback(contextGk.eventKey)
			
		);

		const isCurrentEventKey = activeEventKey === contextGk.eventKey;
		return (
			<Fragment>
				<Link
					to="#"
					onClick={decoratedOnClick}
					aria-expanded={isCurrentEventKey}
					className="d-flex align-items-center text-inherit text-decoration-none h4 mb-0"
					data-bs-toggle="collapse"
					aria-controls="courseTwo"
				>
					<div className="me-auto">{contextGk.children}</div>
					<span className="chevron-arrow ms-4">
						<i className="fe fe-chevron-down fs-4"></i>
					</span>
				</Link>
			</Fragment>
		);
	};

	useEffect(() => {

		setActiveKeyId(props.accordionItems.currentModuleId.toUpperCase());

	  }, [props.accordionItems]);

	return (
		<Fragment>
			
			<Accordion activeKey={activeKeyId} defaultActiveKey={props.accordionItems.currentModuleId.toUpperCase()}>
				<ListGroup as="ul" variant="flush">
					{props.accordionItems.modules.map((item, index) => {

							return (
								<ListGroup.Item
									key={index}
									as="li"
									className={`${props.itemClass ? props.itemClass : ''}`} onClick={() => changeActiveKey(item.moduleId.toUpperCase())}
								>
									<ContextAwareToggle eventKey={item.moduleId.toUpperCase()}>
										{item.moduleTitle}
									</ContextAwareToggle>
									<Accordion.Collapse eventKey={item.moduleId.toUpperCase()}>
										<ListGroup className="py-4" as="ul">
											{item.lessons.map((subitem, subindex) => (
												<ListGroup.Item
													key={subitem.lessonId}
													as="li"
													//disabled={subitem.locked}
													className="px-0 py-1 border-0" style={{backgroundColor: props.accordionItems.currentLessonId == subitem.lessonId ? '#f0f8ff' : ''}}>
													<Link
														to="#"
														className={`d-flex justify-content-between align-items-center text-inherit text-decoration-none`}
														onClick={() => props.onClick(subitem.lessonId)}
													>
														<div className="text-truncate">
															
																{subitem.locked && 
																<span className="icon-shape bg-light icon-sm rounded-circle me-2">
																	<i className="fe fe-lock fs-4"></i>
																</span>
																}
																{subitem.lessonUserFinished == true && 
																<span className="icon-shape icon-sm rounded-circle me-2 btn-success" style={{backgroundColor: 'green'}}>
																	<i className="fe fe-check fs-4" style={{color: 'white'}}></i>
																</span>
																}
																{subitem.lessonUserFinished == false && 
																<span className="icon-shape bg-light icon-sm rounded-circle me-2">
																	<Icon path={mdiPlay} size={0.8}/>
																</span>
																}
															
															<span className="fs-5">{subitem.lessonName}</span>
														</div>
														<div className="text-truncate">
															<span>{subitem.duration}</span>
														</div>
													</Link>
												</ListGroup.Item>
											))}
										</ListGroup>
									</Accordion.Collapse>
								</ListGroup.Item>
							);
					})}
				</ListGroup>
			</Accordion>
		</Fragment>
	);
};

export default GKAccordionDefault;

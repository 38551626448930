// import node module libraries
import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

import NavbarDefault from './navbars/NavbarDefault';


import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';

const AuthLayout = (props: any) => {

    const { instance } = useMsal();
    let activeAccount: any;

    if (instance) {
        activeAccount = instance.getActiveAccount();

        if(!activeAccount) {
            instance.loginRedirect(loginRequest).catch((error) => console.log(error));
        }
    }
    else
    {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    }
    

	return (
		<Fragment>
            <AuthenticatedTemplate>
                <main>
                    {props.children}
                    <Outlet />
                </main>
			</AuthenticatedTemplate>
		</Fragment>
	);
};

export default AuthLayout;
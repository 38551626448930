// import node module libraries
import React from 'react';
import { Link } from 'react-router-dom';

// import sub components
import LevelIcon from './LevelIcon';

// import custom components
import GKTippy from '../elements/tooltips/GKTippy';

const LevelIconWithTooltip = (level: any) => {
	if (level === 'Beginner' || level === 'Intermediate' || level === 'Advance') {
		return (			
			<GKTippy content={level}>
				<Link to="#">
					<LevelIcon level={level} />
				</Link>
			</GKTippy>			
		);
	} else {
		return (<></>);
	}
};
export default LevelIconWithTooltip;

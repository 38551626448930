// import node module libraries
import React, { Fragment, useState, useEffect } from 'react';
import { Col, Row, Container, Card, Dropdown, Image, Breadcrumb, BreadcrumbItem, Spinner, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import 'simplebar/dist/simplebar.min.css';
import ProfileCover from '../../components/headers/ProfileCover';

// import custom components
import GKAccordionDefault from '../../components/accordions/GKAccordionDefault';
import { useMsal } from '@azure/msal-react';
import Axios from 'axios';
import { loginRequest, b2cPolicies } from '../../authConfig';
const Avatar3 = require('../../assets/images/avatar/coruja.png');

// import data
import GKVimeo from '../../components/video/GKVimeo';
import AssetsCard from '../../components/courses/AssetsCard';
import CommentsCard from '../../components/courses/CommentsCard';

interface _customToggle {
    children: any;
    onClick: any
}

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef((props : _customToggle, ref: any) => (
	<Link
		to="#"
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			props.onClick(e);
		}}
	>
		{props.children}
	</Link>
));


export const CourseResume = () => {

	const [courseData, setCourseData] = useState<any>();
	const [commentsData, setCommentsData] = useState<any>();
	const [configHeader, setConfigHeader] = useState<any>();
	const [lastLessonLastModule, setLastLessonLastModule] = useState<boolean>();
	const [lessonSelected, setLessonSelected] = useState<any>();
	const [dashboardData, setDashboardData] = useState({});
	let { id } = useParams();

	const { instance } = useMsal();
	
	let activeAccount: any;

	if (instance) {
		activeAccount = instance.getActiveAccount();		
	}

	async function getConfigAxios() {

		let configAxios;

		await instance.acquireTokenSilent(loginRequest).then(tokenResponse => {

			configAxios = {
				headers: {
					Authorization: `Bearer ${tokenResponse.accessToken}`
				}			
			};
			
			setConfigHeader(configAxios);
		});
	}

	function onClickHandler(lessonId)
	{
		if(courseData != undefined)
		{
			// Use the filter method to filter lessons by lessonId
			const filteredLessons = courseData.modules.flatMap(module => module.lessons)
			.filter(lesson => lesson.lessonId === lessonId);

			setLessonSelected(filteredLessons[0]);
			location.replace("#videoPlayer")
			
		}
	}

	async function nextLesson()
	{
		if(!lessonSelected.lessonUserFinished)
		{
			
			Axios.post( 
				process.env.REACT_APP_URLAPI + '/Lessons/ChekingLesson',
				{
					LessonId: lessonSelected.lessonId
				},  
				configHeader
				).then(result => {
					redirectNextLesson();
				}).catch(err => {
					if(err.response.status == "401"){
						getConfigAxios();
						nextLesson();
					}
					if(err.response.status == "409"){
						redirectNextLesson();
					}
				});
		}
		else
		{
			redirectNextLesson();
		}
	}

	async function postCurrentUserLesson()
	{
		if(lessonSelected != null)
		{
			const allLessons = getAllLessonsWithModuleId(); 
			const currentLesson = allLessons.filter((obj) => obj.lessonId.toUpperCase() == lessonSelected.lessonId.toUpperCase())[0];
	
			Axios.post( 
				process.env.REACT_APP_URLAPI + '/Lessons/SetUserCurrentLesson',
				{
					LessonId: lessonSelected.lessonId,
					ModuleId: currentLesson.moduleId,
					CoursePermalink:  id
				},  
				configHeader
				).then(result => {
					getCourse();
				}).catch(err => {
					console.log(err);
				});
		}
	}

	function insertLessonComment(comment: string, parentId: string)
	{
		if(lessonSelected != null)
		{
			
			Axios.post( 
				process.env.REACT_APP_URLAPI + '/Lessons/Comments',
				{
					lessonId: lessonSelected.lessonId,
					comment: comment,
					parent: parentId
				},  
				configHeader
				).then(result => {
					getLessonComments();
				}).catch(err => {
					console.log(err);
				});
		}
	}

	async function getLessonComments()
	{
		if(lessonSelected != null)
		{
			Axios.get( 
				process.env.REACT_APP_URLAPI + '/Lessons/Comments/' + lessonSelected.lessonId,		  
				configHeader
				).then(result => {
					setCommentsData(result.data);
				}).catch(err => {
					console.log(err);
				});
		}
	}

	function getAllLessonsWithModuleId()
	{
		const allLessons = courseData.modules.flatMap(({ moduleId, orderItem, lessons }) => lessons.map(lesson => ({ ...lesson, moduleId, moduleOrderItem: orderItem })));	
		return allLessons;
	}

	async function isLastLessonAndLastModule() {

		const allLessons = getAllLessonsWithModuleId();
		const currentLesson = allLessons.filter((obj) => obj.lessonId.toUpperCase() == lessonSelected.lessonId.toUpperCase())[0];		
		const nextNodeLesson = allLessons.filter((obj) => obj.orderItem == lessonSelected.orderItem + 1 && obj.moduleId.toUpperCase() == currentLesson.moduleId.toUpperCase());
		
		if(nextNodeLesson == null || nextNodeLesson.length == 0)
		{
			let nextModule = allLessons.filter(obj => obj.moduleOrderItem === currentLesson.moduleOrderItem + 1)
			if(nextModule == null || nextModule.length == 0)
			{
				setLastLessonLastModule(true);
			}
			else
				setLastLessonLastModule(false);
		}
		else
			setLastLessonLastModule(false);
	}

	async function redirectNextLesson()
	{		
		const allLessons = getAllLessonsWithModuleId();
		const currentLesson = allLessons.filter((obj) => obj.lessonId.toUpperCase() == lessonSelected.lessonId.toUpperCase())[0];		
		const nextNodeLesson = allLessons.filter((obj) => obj.orderItem == lessonSelected.orderItem + 1 && obj.moduleId.toUpperCase() == currentLesson.moduleId.toUpperCase());

		if(nextNodeLesson != null && nextNodeLesson.length > 0)
		{
			onClickHandler(nextNodeLesson[0].lessonId);
		}
		else
		{
			//IF ITEM IS THE LAST LESSON OF MODULE
			let nextModule = allLessons.filter(obj => obj.moduleOrderItem === currentLesson.moduleOrderItem + 1)

			if(nextModule != null && nextModule.length > 0)
			{
				onClickHandler(nextModule[0].lessonId);
			}
		}
		location.replace("#");
	}

	async function getCourse()
	{
		Axios.get( 
			process.env.REACT_APP_URLAPI + '/Courses/' + id,		  
			configHeader
			).then(result => {
				setCourseData(result.data);
			}).catch(err => {
				console.log(err);
			});
		
	}

	useEffect(() => {
		const _dashboardData = {
			avatar: Avatar3,
			name: activeAccount && activeAccount.idTokenClaims.given_name.toLocaleString() + " " + activeAccount.idTokenClaims.family_name.toLocaleString(),
			linkname: 'Account Setting',
			link: '/marketing/student/student-edit-profile/',
			verified: true,
			outlinebutton: false,
			level: 'Beginner'
		};

		getConfigAxios();

		setDashboardData(_dashboardData);

	  }, []);

	  useEffect(() => {
		if(configHeader != undefined)
			 getCourse();		
	  }, [configHeader]);

	  useEffect(() => {
		if(configHeader != undefined)
			isLastLessonAndLastModule();
			postCurrentUserLesson();
			getLessonComments();
	  }, [lessonSelected]);

	  
	  useEffect(() => {

		if(courseData != null)
		{
			let filteredLessons: any;
			// Use the filter method to filter lessons by lessonId
			if(lessonSelected == null)
			{
				if(courseData.currentLessonId == "00000000-0000-0000-0000-000000000000")
				{
					// Use the filter method to filter lessons by lessonId
					filteredLessons = courseData.modules.flatMap(module => module.lessons);
				}
				else
				{
					filteredLessons = courseData.modules.flatMap(module => module.lessons)
						.filter(lesson => lesson.lessonId === courseData.currentLessonId);
					
				}
				setLessonSelected(filteredLessons[0]);
			}
		}
	  }, [courseData]);

	return (
		<Fragment>
			
			<main>
				<section className="mt-6">
					<Container>
						<Row>
							<Col lg={12} md={12} sm={12}><ProfileCover dashboardData={dashboardData} /></Col>
						</Row>
						
						{!courseData && 							
							<Row>
								<Col lg={12} md={12} sm={12}>
									<div className="d-flex justify-content-center">																	
										<Spinner animation="border" role="status" style={{marginTop: '20px'}} variant="primary">
											<span className="visually-hidden">Loading...</span>
										</Spinner>																	
									</div>
								</Col>
							</Row>
						}			
						{courseData && 
						<div>
							<Row>
								<Col lg={12} md={12} sm={12} className="mb-3 mb-md-0">
									<Breadcrumb style={{marginTop: '10px'}}>
										<Breadcrumb.Item href='/'>Lista de Cursos</Breadcrumb.Item>
										<Breadcrumb.Item active>{courseData.title}</Breadcrumb.Item>
									</Breadcrumb>
								</Col>
							</Row>
							<Row>
								<Col sm={4} md={4} lg={4}>
									<section className="card course-sidebar " id="courseAccordion">
										
											<Card>
												<Card.Header>      
													<h4 className="mb-0">                                
													{courseData.title}</h4>
												</Card.Header>
												{/* Course Index Accordion */}
												<GKAccordionDefault accordionItems={courseData} onClick={onClickHandler}/>
											</Card>
										
									</section>
								</Col>
								<Col sm={8} md={8} lg={8}>
									{/*  Tab content  */}
									<div id="videoPlayer"></div>
									
									{lessonSelected &&
									<>
										<Row>
											<Col sm={12} md={12} lg={12}>
												<div className="content" style={{marginTop: '20px'}} >
													{/*  Video */}
													
													<div className="d-flex align-items-center justify-content-between mb-4">
														<div>
															<h3 className=" mb-0  text-truncate-line-2">
															{lessonSelected.lessonName}
															</h3>
														</div>
													</div>
													<div className="d-flex justify-content-between">
														<h4 className=" mb-0">
														{ <div dangerouslySetInnerHTML={{ __html: lessonSelected.lessonDescription }} /> }
														</h4>
													</div>
													{lessonSelected.lessonVideoUrl != undefined && lessonSelected.lessonVideoUrl != "" && 
													<div 
														className="embed-responsive position-relative w-100 d-block overflow-hidden p-0" style={{marginBottom: '40px'}}
													>
														<br /><GKVimeo key={lessonSelected.lessonId} videoId={lessonSelected.lessonVideoUrl} />
													</div>
													}
													<div className="d-grid gap-2">
														{!lastLessonLastModule && <Button type="button" className="btn btn-success" size="lg" onClick={nextLesson} >Próxima Aula</Button>}														
													</div>
												</div>
											</Col>
										</Row>
										<Row>
											<Col sm={12} md={12} lg={12}>
												{lessonSelected.lessonAssets != undefined && lessonSelected.lessonAssets.length > 0 && 
													<div style={{marginTop: '20px', marginBottom: '40px'}}>
														<AssetsCard items={lessonSelected.lessonAssets} key='ddf' />
													</div>
													}
											</Col>
										</Row>
										<Row>
											<Col sm={12} md={12} lg={12}>												
												<div style={{marginTop: '20px', marginBottom: '40px'}}>
													<CommentsCard commentsItems={commentsData} key='comments' SaveComment={insertLessonComment} />
												</div>												
											</Col>
										</Row>
									</>
									}
																
								</Col>							
							</Row>
						</div>
						}
					</Container>
				</section>
				
			</main>
			
		</Fragment>
		
	);
};

export default CourseResume;

// import node module libraries
import React from 'react'
import { useEffect, useState } from 'react';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { EventType, RedirectRequest } from '@azure/msal-browser';
import { b2cPolicies, protectedResources } from './authConfig';
import { compareIssuingPolicy } from './Utils/ClaimUtils';
import { Col, Row, Spinner } from 'react-bootstrap';

// import layouts
import AllRoutes from './routes/AllRoutes';
import ScrollToTop from './layouts/dashboard/ScrollToTop';

const Pages = () => {
    /**
     * useMsal is hook that returns the PublicClientApplication instance,
     * an array of all accounts currently signed in and an inProgress value
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const { instance } = useMsal();
    const [authLoaded, setAuthLoaded] = useState<boolean>(false);

    useEffect(() => {
        
        const callbackId = instance.addEventCallback((event) => {
            //setAuthLoaded(false);
            if (
                (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                event.payload.account
            ) {
                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth
                 * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
                 * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
                 * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.editProfile)) {
                    // retrieve the account from initial sing-in to the app
                    const originalSignInAccount = instance
                        .getAllAccounts()
                        .find(
                            (account) =>
                                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                                account.idTokenClaims.sub === event.payload.idTokenClaims.sub && 
                                compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signUpSignIn)        
                        );

                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: originalSignInAccount,
                    };

                    // silently login again with the signUpSignIn policy
                    instance.ssoSilent(signUpSignInFlowRequest);
                }

                /**
                 * Below we are checking if the user is returning from the reset password flow.
                 * If so, we will ask the user to reauthenticate with their new password.
                 * If you do not want this behavior and prefer your users to stay signed in instead,
                 * you can replace the code below with the same pattern used for handling the return from
                 * profile edit flow
                 */
                if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        scopes: [
                            ...protectedResources.apiLMS.scopes.read
                        ],
                    };
                    instance.loginRedirect(signUpSignInFlowRequest);
                }
            }

            if (event.eventType === EventType.LOGIN_FAILURE) {
                // Check for forgot password error
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        scopes: [],
                    };
                    instance.loginRedirect(resetPasswordRequest);
                }
            }
        });

        return () => {
            setAuthLoaded(true);
            if (callbackId) {
                setAuthLoaded(false);
                instance.removeEventCallback(callbackId);
            }

        };
        // eslint-disable-next-line
       
    }, [instance]);

    return (
		<>
          {authLoaded && 							
                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <div className="d-flex justify-content-center">																	
                            <Spinner animation="border" role="status" style={{marginTop: '20px'}} variant="primary">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>																	
                        </div>
                    </Col>
                </Row>
            }	
			<ScrollToTop />     
            <AllRoutes />
		</>
    );
};

const App = ({ instance }: { instance: any }) => {
    

    return (
        <MsalProvider instance={instance}>
         
            <Pages />
          
        </MsalProvider>
    );
}

export default App;
// import node module libraries
import React from 'react';
import { Col, Row, Dropdown, Table, Card, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface iAssetsCardProps {
	items: any[]
}

import {
	Youtube,
	Vimeo,
	FilePdf,
	Link as Hyperlink,
	Spotify,
	Soundwave,
	FileEarmarkSlides,
	Exclamation
} from 'react-bootstrap-icons';




const AssetsCard = (
    props: iAssetsCardProps
) => {
	

	const AssetsType = (assetType: any) => {
		
		switch(assetType.assetType) {
	
		  case "pdf":   return <FilePdf size={24} className="text-danger" />;
		  case "youtube": return <Youtube size={24} className="text-danger" />;
		  case "vimeo": return <Vimeo size={24} className="text-danger" />;
		  case "link":  return <Hyperlink size={24} className="text-danger" />;
		  case "spotify":  return <Spotify size={24} className="text-danger" />;
		  case "soundcloud":  return <Soundwave size={24} className="text-danger" />;
	
		  default: return <FilePdf size={24} className="text-danger" />
		}
	  }
											
    return (
        <div>
            <Row>
				<Col lg={12} md={12} sm={12}>
					<Card>
						<Card.Header>
							<h4 className="mb-0">Arquivos auxiliares</h4>
						</Card.Header>
						<Card.Body className="p-0">
							<div className="table-responsive border-0 overflow-y-hidden">
								<Table hover className="text-nowrap mb-0 table-centered">
									<thead className="table-light">
										<tr>
                                            <th></th>
                                            <th>Nome do Arquivo</th>
                                            <th></th>
                                        </tr>
									</thead>
									<tbody>
                                    {props.items.map((element, index) => (
										
                                        <tr>
                                            <td>
												<AssetsType assetType={element.lessonAssetType.toLowerCase() } />												
											</td>
                                            <td>{element.lessonAssetTitle}</td>
                                            <td>
                                            <Link to={element.lessonAssetURL} className="me-3 text-muted" target="_new">
                                                <i className="fe fe-download fs-5"></i>
                                            </Link>
                                            </td>
                                        </tr> 
                                    ))}
									</tbody>
								</Table>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
            
        </div>
    );

	
};


export default AssetsCard;

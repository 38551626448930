// import node module libraries
import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Avatar }  from '../../components/elements/bootstrap/Avatar';
// import custom components
import LevelIconWithTooltip from '../miscellaneous/LevelIconWithTooltip';

// import media files

const ProfileBackground = require('../../assets/images/background/profile-bg.jpg');

interface IProfileCoverProps {
	dashboardData: any 
}

const ProfileCover = (props: IProfileCoverProps ) => {
	return (
		<Row className="align-items-center">
			<Col xl={12} lg={12} md={12} sm={12}>
				<div
					className="pt-16 rounded-top-md"
					style={{
						background: `url(${ProfileBackground})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover'
					}}
				>
					{' '}
				</div>
				<Card className="px-4 pt-2 pb-4 rounded-0 rounded-bottom shadow-sm">
					<div className="d-flex align-items-end justify-content-between  ">
						<div className="d-flex align-items-center">
							<div className="me-2 position-relative d-flex justify-content-end align-items-end mt-n5">
								
								{props.dashboardData.verified ? (
									<Link
										to="#"
										className="position-absolute top-0 end-0"
										data-bs-toggle="tooltip"
										data-placement="top"
										title=""
										data-original-title="Verified"
									>
										
									</Link>
								) : (
									''
								)}
							</div>
							<div className="lh-1">
								<h2 className="mb-0">
									<Avatar size="lg" type="initial" name={props.dashboardData.name} className="rounded-circle" alt={props.dashboardData.name} /> &nbsp;
									{props.dashboardData.name}{' '}
									<LevelIconWithTooltip level={props.dashboardData.level} />{' '}
								</h2>
								
							</div>
						</div>
						<div>
							
						</div>
					</div>
				</Card>
			</Col>
		</Row>
	);
};

export default ProfileCover;

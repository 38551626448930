// import node module libraries
import React, { Fragment, useState, useRef } from 'react';
import {
	Reply
} from 'react-bootstrap-icons'
import { Image, Form, Button, Row, Col, Container} from 'react-bootstrap';
import { Avatar }  from '../../components/elements/bootstrap/Avatar';
import moment from 'moment-timezone';
interface CommentsCardDefaultProps {
    commentsItems: any;
    SaveComment: any;
}
const CommentsCard = (props: CommentsCardDefaultProps ) => {

    const [commentText, setCommentText] = useState<string>();
    const [replyCommentID, setReplyCommentID] = useState<string>();
    const textareaRef = useRef(null);

    function loadCommentText(value: any)
    {
        setCommentText(value.currentTarget.value);
    }

    function ConvertTimeZone(item: any)
    {
        var testDateUtc = moment.utc(item);
        var localDate = moment(testDateUtc).local();

        return moment(localDate).startOf('minutes').fromNow();
    }
 
    function StartReplyComment(id: any, fullname:any)
    {
        setReplyCommentID(id);
        setCommentText("@" + fullname + " ");
        
        if (textareaRef.current) {
            textareaRef.current.focus();
            const length = textareaRef.current.value.length;
            textareaRef.current.setSelectionRange(length, length);
        }
    }

    function SaveComment(commentText: string, replyCommentID:string)
    {
        let _comments = commentText;
        let _replyCommentID = replyCommentID
        console.log(_replyCommentID);
        props.SaveComment(_comments, _replyCommentID);
        setReplyCommentID(undefined);
        setCommentText("");
    }

    return (
		<Fragment>
			<Row>
                <Col sm={12} md={12} lg={12}>
                    <h3><br />Comentários sobre esta aula</h3>
                </Col>		
            </Row>            	
			<hr />
			<Row>
                <Col sm={12} md={12} lg={12}>
				{/* Rating */}
                
				{props.commentsItems && props.commentsItems.map((item, index) => (
                 <Container>
                        {item.parent == "00000000-0000-0000-0000-000000000000" &&
                        <div>
                            <Row>
                                <Col sm={12} md={12} lg={12} style={{paddingTop: '10px', backgroundColor: 'white'}}>
                                {index > 0 && <hr />}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={1} md={1} lg={1} style={{ padding: '10px', backgroundColor: 'white'}}>
                                    <Avatar size="lg" type="initial" name={item.fullName} className="rounded-circle" alt={item.fullName} />
                                </Col>
                                <Col sm={8} md={11} lg={11} style={{ backgroundColor: 'white'}}>
                                    <Row>
                                        <Col sm={12} md={12} lg={12}>
                                        <h4>
                                        {item.fullName}<span className="ms-1 fs-6 text-muted">{ConvertTimeZone(item.dateComment)}</span>
                                        </h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={12} lg={12}>
                                            <div
                                            dangerouslySetInnerHTML={{
                                                __html: item.comment
                                            }}
                                        />
                                        </Col>
                                    </Row>
                                </Col>		
                            </Row>
                            <Row>
                                <Col sm={12} md={12} lg={12} style={{padding: '20px', backgroundColor: 'white'}}>
                                            <Button onClick={() => StartReplyComment(item.id, item.fullName)}>Responder Comentário <Reply  size={24}/></Button>
                                </Col>
                            </Row>
                        </div>
                        }
                        {item.parent != "00000000-0000-0000-0000-000000000000" &&
                        <div>
                            <Row>
                                <Col sm={12} md={12} lg={12} style={{paddingTop: '20px', backgroundColor: 'white'}}>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="1" md="1" lg="1" style={{backgroundColor: 'white'}}>
                                   
                                </Col>
                                <Col sm="1" md="1" lg="1" style={{ paddingTop: '10px', backgroundColor: 'white'}}>
                                    <Avatar size="lg" type="initial" name={item.fullName} className="rounded-circle" alt={item.fullName} />
                                </Col>
                                <Col sm="10" md="10" lg="10" style={{backgroundColor: 'white'}}>
                                    <Row>
                                        <Col sm={12} md={12} lg={12}>
                                        <h4>
                                        {item.fullName}<span className="ms-1 fs-6 text-muted">{ConvertTimeZone(item.dateComment)}</span>
                                        </h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={12} lg={12}>
                                            <div
                                            dangerouslySetInnerHTML={{
                                                __html: item.comment
                                            }}
                                        />
                                        </Col>
                                    </Row>
                                </Col>		
                            </Row>
                            <Row>
                                <Col sm={12} md={12} lg={12} style={{paddingTop: '20px', backgroundColor: 'white'}}>
                                </Col>
                            </Row> 	
                        </div>
                        }
                    </Container>
                    
				))}
                 <div>
                    <Row>
                        <Col sm={12} md={12} lg={12} style={{marginTop: '20px'}}>
                            <h4 className="mb-4">Adicionar um novo comentário</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                        <Form>                    
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Comentário</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={loadCommentText} value={commentText} ref={textareaRef}/>
                                <br></br>
                                <Button type="button" className="btn btn-success" size="lg" onClick={() => SaveComment(commentText, replyCommentID)} >Salvar</Button>
                            </Form.Group>
                            </Form>
                        </Col>
                    </Row>                 
                </div>
                </Col>		
            </Row>  
		</Fragment>
	);

}
export default CommentsCard;


/***************************
Component : Avatar
****************************

Availalble Parameters

size        : Required, possible options are xxl, xl, lg, md, sm, xs
type        : Required, possible options are image , initial
src         : Image source is required if type = image
alt         : Optional, it's used for alt tag of image avtar, which is helpful for invalid url or broken link.
name        : Name is required if type = initial
className   : Optional class list e.g. circle, rounded, rounded-circle, bg-info etc...
status      : Optional, possible options are online, away, offline, busy
soft        : Optional, if it's set it will show soft background color which is only usefule for type = initial
showExact	: Optional, specify this parameter with name para, if you want to show exactly name value rather acronym format.
bodyClasses	: Optional, if you want to apply classes to avatar body i.e. span like me-3, ms-3 etc... you can use this property.

Added in v1.2.0
imgtooltip	: Optional - Boolean - Default=false, if you specify this parameter, it will show name para value in tooltip.

*/
import React from 'react';
// import node module libraries
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';

// import custom components
import GKTippy from '../tooltips/GKTippy';

interface AvatarDefaultProps {
    size: any,
	type: any,
	src: any,
	alt: any,
	name: any,
	className: any,
	status: any,
	soft: any,
	variant: any,
	showExact: any,
	imgtooltip: any,
	bodyClasses: any
}

const Avatar = (props: AvatarDefaultProps) => {
	

	const GetAvatar = () => {
		if (props.type === 'initial' && props.name) {
			const regex = /^(\w)[^\w]*.*\b(\w)/;
			var matches = props.name.match(regex);
			var acronym = props.showExact ? name : matches[1] + matches[2];
			if (props.soft) {
				return props.imgtooltip ? (
					<GKTippy content={name}>
						<span
							className={`avatar avatar-${props.size} avatar-${props.variant}-soft me-0 mb-2 mb-lg-0`}
						>
							<span className={`avatar-initials ${props.className}`}>{acronym}</span>
						</span>
					</GKTippy>
				) : (
					<span
						className={`avatar avatar-${props.size} avatar-${props.variant}-soft me-0 mb-2 mb-lg-0`}
					>
						<span className={`avatar-initials ${props.className}`}>{acronym}</span>
					</span>
				);
			}
			if (props.imgtooltip && props.name) {
				return (
					<GKTippy content={props.name}>
						<span
							title={props.alt}
							className={`avatar avatar-${props.size} avatar-primary me-0 mb-2 mb-lg-0 ${
								status ? 'avatar-indicators avatar-' + status : ''
							}`}
						>
							<span className={`avatar-initials bg-${props.variant} ${props.className}`}>
								{acronym}
							</span>
						</span>
					</GKTippy>
				);
			} else {
				return (
					<span
						title={props.alt}
						className={`avatar avatar-${props.size} avatar-primary me-0 mb-2 mb-lg-0 ${
							props.status ? 'avatar-indicators avatar-' + props.status : ''
						}`}
					>
						<span className={`avatar-initials bg-${props.variant} ${props.className}`}>
							{acronym}
						</span>
					</span>
				);
			}
		} else if (props.type === 'image' && props.src) {
			if (props.imgtooltip && props.name) {
				return (
					<span
						className={`avatar avatar-${props.size} me-1 ${
							status ? 'avatar-indicators mb-2 mb-lg-0 avatar-' + props.status : ''
						} ${props.bodyClasses ? props.bodyClasses : ''}`}
					>
						<GKTippy content={name}>
							<Image
								src={props.src}
								alt={props.alt}
								className={`mb-2 mb-lg-0 ${props.className}`}
							/>
						</GKTippy>
					</span>
				);
			} else {
				return (
					<span
						className={`avatar avatar-${props.size} me-0 ${
							status ? 'avatar-indicators mb-2 mb-lg-0 avatar-' + status : ''
						}`}
					>
						<Image
							src={props.src}
							alt={props.alt}
							className={`mb-2 mb-lg-0 ${props.className}`}
						/>
					</span>
				);
			}
		} else {
			return (
				<span
					dangerouslySetInnerHTML={{
						__html: 'Required Avatar parameter not found'
					}}
				></span>
			);
		}
	};
	return <GetAvatar />;
};

Avatar.propTypes = {
	size: PropTypes.oneOf(['xxl', 'xl', 'lg', 'md', 'sm', 'xs']),
	type: PropTypes.oneOf(['image', 'initial']),
	src: PropTypes.string,
	alt: PropTypes.string,
	name: PropTypes.string,
	status: PropTypes.oneOf(['online', 'away', 'offline', 'busy']),
	className: PropTypes.string,
	variant: PropTypes.oneOf([
		'primary',
		'secondary',
		'success',
		'danger',
		'warning',
		'info',
		'light',
		'dark'
	]),
	soft: PropTypes.bool,
	showExact: PropTypes.bool,
	imgtooltip: PropTypes.bool,
	bodyClasses: PropTypes.string
};

Avatar.defaultProps = {
	className: '',
	size: 'md',
	variant: 'primary',
	soft: false,
	showExact: false
};

const AvatarGroup = (props) => {
	return (
		<div className={`avatar-group ${props.className ? props.className : ''}`}>
			{props.children}
		</div>
	);
};
const Ratio = (props) => {
	const { src, size, className } = props;
	return (
		<span>
			<Image
				src={src}
				alt=""
				className={`img-4by3-${size} mb-2 mb-lg-0 ${className}`}
			/>
		</span>
	);
};

export  { Avatar, AvatarGroup, Ratio };

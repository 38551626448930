// import node module libraries
import React, { Fragment } from 'react';

const youtubeRender = (props) => {
	return (
		<Fragment>
			<div style={props.STYLES.videoIframeContainer}>
				<iframe width="640" height="360" id={'ifr_' + props.element.split('/')[props.element.split('/').length - 1]} src={'https://www.youtube.com/embed/' + props.element.split('/')[props.element.split('/').length - 1]} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" style={props.STYLES.videoIframe} allowfullscreen></iframe>
			</div>
		</Fragment>
	);
}

const GKVimeo = (props) => {
	
	const STYLES = { videoIframeContainer: { padding: '56.25% 0 0 0', position: 'relative', marginBottom: '25px' }, videoIframe: { position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' } }

	const videos = props.videoId.split(';');
	
	return (		
		<div>
			{videos.map(element => {
				return(
					<div key={element}>
						{element.toUpperCase().indexOf('YOUTU') > -1 && 
							<Fragment>
								<div style={STYLES.videoIframeContainer}>
									<iframe width="640" height="360" id={'ifr_' + element.split('/')[element.split('/').length - 1]} src={'https://www.youtube.com/embed/' + element.split('/')[element.split('/').length - 1]} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" style={STYLES.videoIframe} allowfullscreen></iframe>
									<br />
								</div>
							</Fragment>
						}
						{element.toUpperCase().indexOf('YOUTU') == -1 && 
							<Fragment>
								<div style={STYLES.videoIframeContainer}>
									<iframe
									title="vimeo-player"
									src={'https://player.vimeo.com/video/' + element}
									width="640"
									height="360"
									id={'ifr_' + element} 
									allow="fullscreen"
									style={STYLES.videoIframe}
									>
									</iframe>
								</div>				
							</Fragment>
						}
					</div>
				)
			})}
			
		</div>
	)
};

export default GKVimeo;

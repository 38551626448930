// import node module libraries
import React from 'react';
import { useContext, Children } from 'react';

// import tippy tooltip
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/scale.css';

// import context file
import { AppConfigContext } from '../../../context/Context';

interface IGKProps {
    content: any,	
	children: any,
	placement?: any
	
}

const GKTippy = (props: IGKProps) => {
	const ConfigContext = useContext(AppConfigContext);
	return (
		<Tippy
			content={
				<small
					className={`fw-bold ${
						ConfigContext.theme.skin === 'light' ? 'text-dark' : ''
					}`}
				>
					{props.content}
				</small>
			}
			theme={ConfigContext.theme.skin === 'light' ? 'dark' : 'light'}
			placement={props.placement}
			animation={'scale'}
		>
			{props.children}
		</Tippy>
	);
};


export default GKTippy;

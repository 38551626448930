// ** Import from react dom
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

// ** Import core SCSS styles
import '../assets/scss/theme.scss';

import DefaultLayout from '../components/DefaultLayout';
import AuthLayout from '../components/AuthLayout';

import Home from '../pages/anonymous/Home';
import StudentDashboard from '../pages/authenticated/Dashboard';
import Course from '../pages/authenticated/Course';

const AllRoutes = () => {
	return (
		<Routes>
			{/* Routes with DefaultLayout */}
			<Route element={<AuthLayout />} >				
				<Route path="/" element={<StudentDashboard />} />
				<Route path="/courses/:id" element={<Course />} />
			</Route>

		</Routes>
	);
};

export default AllRoutes;
